@import "animate.css";

.project-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
    font-size: 20px;
    transition: scale 0.3s ease-in;
    animation:zoomIn 2s;
    max-width: 400px;
    z-index: 0;
}

.project-container:hover{
    cursor: pointer;
}

.project-image{
    display: inline-block;
    text-align: center;
    overflow: hidden;
    /* height: fit-content; */
    width: 100%;
    height:15em;
}

.project-image > img{
    display: block;
    aspect-ratio: 16 / 9;
    object-position: center;
    object-fit: cover;
    height:100%;
    width: 100%;
}

img.ces-device-simulation{
    object-fit: contain;
}

img.desktop-app{
    object-fit: contain;
}



.project-text{
    width: 100%;
    background-color:var(--complementary-colour-blue);
    text-align: center;
}

.project-middle{
    width: 100%;
    /* border-bottom: 2px solid gray; */
    color:var(--dominant-colour);
}

.date{
    color:var(--complementary-colour-black);
}

.summary{
    width: 100%;
    background-color: var(--dominant-colour);
}

.tools{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.tool{
    background-color: var(--dominant-colour);
    color:var(--complementary-colour-black);
    border-radius: 2px;
    padding: 0.25em;
    margin:0.25em;
    font-size: 0.9em;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
}

.project-links{
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color:var(--complementary-colour-blue);
    color:var(--dominant-colour);
    padding:0.5em;
}

.project-links .icon{
    color:var(--dominant-colour);
    font-size: 1.5em;
    transition: color 0.3s ease-in;
}

.demo-link{
    display: flex;
    justify-content: center;
    gap:0.5em;
    color: var(--dominant-colour);
    transition: color 0.3s ease-in;
}

.demo-link:visited{
    color: var(--dominant-colour);
}

.demo-link:hover{
    color:var(--complementary-colour-purple);
    cursor: pointer;
}

.project-links .icon:hover{
    color:var(--complementary-colour-purple);
    cursor: pointer;
}

@media screen and (min-width:320px){
    .project-container{
        font-size: 15px;
        width:17.5em;
    }
}

@media screen and (min-width:1280px) {
    .project-container:hover{
        scale:1.1;
        cursor: pointer;
    }

    .project-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* box-shadow: 5px 10px 18px gray; */
        border: none;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
        font-size: 20px;
        transition: scale 0.3s ease-in;
        animation:zoomIn 2s;
        /* max-width: 400px; */
        width: 20vw;
        /* height: 80vh; */
    }

    
    .project-image{
        display: inline-block;
        text-align: center;
        overflow: hidden;
        /* height: fit-content; */
        width: 100%;
        /* height:15em; */
        height: 30vh;
    }
    
    .project-image > img{
        display: block;
        aspect-ratio: 16 / 9;
        object-position: center;
        object-fit: cover;
        height:100%;
        width: 100%;
    }

    img.ces-device-simulation{
        object-fit: contain;
    }
                
    .project-text{
        width: 100%;
        background-color:var(--complementary-colour-blue);
        text-align: center;
    }
    
    .project-middle{
        width: 100%;
        padding:0 10px 0 10px;
        /* border-bottom: 2px solid gray; */
        color:var(--dominant-colour);
    }

    .project-middle a{
        font-size: 1vw;
    }

    .project-middle .title{
        font-size: 1.25vw;
    }
    
    .date{
        color:var(--complementary-colour-black);
        font-size: 1.1vw;
    }
    
    .summary{
        width: 100%;
        background-color: var(--dominant-colour);
        font-size: 1.1vw;
        padding:0 10px 0 10px;
        max-height: 10vh;
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3; /* Show only 3 lines */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    
    .tools{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        font-size: 1vw;
    }
    
    .tool{
        background-color: var(--dominant-colour);
        color:var(--complementary-colour-black);
        border-radius: 2px;
        padding: 0.25em;
        margin:0.25em;
        font-size: 1vw;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
    }
    
    .project-links{
        display: flex;
        justify-content: space-around;
        width: 100%;
        background-color:var(--complementary-colour-blue);
        color:var(--dominant-colour);
    }
    
    .project-links .icon{
        color:var(--dominant-colour);
        font-size: 1.25vw;
        transition: color 0.3s ease-in;
    }
}