@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@800&display=swap');
@import "animate.css";

*{
  box-sizing:border-box;
  text-decoration: none;
  list-style: none;
}

:root{
  --dominant-colour:#F2FAFF;
  --complementary-colour-blue:#0a5a93;
  --complementary-colour-torquoise:#3189A0;
  --complementary-colour-purple:#7267B0;
  --complementary-colour-grey:#444655;
  --complementary-colour:#B1BBF3;
  --complementary-colour-black: #001241;
  --main-font:'EB Garamond', serif;
}

body{
  margin:0;
  background-color: var(--dominant-colour);
  font-family:var(--main-font);
  color:#001241;
}

h1{
  color:var(--complementary-colour-blue);
}

/* a{
  color: #001241;
}

a:visited{
  color: #001241;
} */



.desktop-nav{
  display: none;
}

.mobile-nav{
  background-color: var(--complementary-colour-blue);
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  width:100%;
  z-index: 4;
}

.mobile-nav ul{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin:0;
  width:100%;
  padding-left:0;
  position:absolute;
  top:100%;
  background-color: var(--complementary-colour-blue);
}

.mobile-nav li{
  height:100%;
  border-radius: 5px;
  font-size: 1.5em;
  width: 100%;
  text-align: center;
}

.mobile-nav li a{
  height: 100%;
  color:var(--dominant-colour);
  transition:color 0.3s ease-in;
  border-radius: 5px;
}

.mobile-navList{
  position:fixed;
  width:100%;
  background-color:var(--complementary-colour-blue);
  color:var(--dominant-colour);
  padding: 0;
  margin: 0;
  top:0.5em;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 3;
  transition: display 0.3s ease ease-in-out;
}

.mobile-navList li{
  font-size: 1.5em;
  color:var(--dominant-colour);
}

.mobile-navList li:visited{
  color:var(--dominant-colour);
}

.mobile-navList a{
  color:var(--dominant-colour);
  font-size: 1.5em;
}


.logo{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo img{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  height: 8vh;
}

#root{
  overflow-x:hidden;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
}

.nav-icon{
  color:var(--dominant-colour);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  font-size: 2em;
  padding: 10px;
}

.mobile-navList .active{
  text-decoration: underline;
}



/* nav li a.active{
  background-color: var(--complementary-colour-grey);
  opacity: 0.8;
  border-bottom: 5px solid gray;
} */


@media screen and (min-width: 1280px){
  .mobile-nav{
    display: none;
  }

  .mobile-navList{
    display: none;
  }

  h1{
    font-size: 53px;
    color:var(--complementary-colour-blue);
  }
  
  .desktop-nav{
    display: flex;
    background-color: var(--complementary-colour-blue);
    position: fixed;
    width:100%;
    justify-content: space-between;
    align-items: center;
    padding:0 1em;
    z-index: 4;
  }
  
  .desktop-nav ul{
    display: flex;
    gap:5em;
    padding: 0;
  }

  .desktop-nav li{
    height:100%;
    font-size: 1.75vw;
    width: 100%;
    text-align: center;
  }
  
  .desktop-nav li a{
    height: 100%;
    color:var(--dominant-colour);
    transition:color 0.3s ease-in;
    border-radius: 5px;
  }

  .desktop-nav li a:hover{
    color:var(--complementary-colour-black);
  }

  .desktop-navList .active{
    text-decoration: none;
  }
}