@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@800&display=swap');
@import "animate.css";

.intro-image{
  flex:1;
  width: 100%;
  animation: slideInLeft 2s;
  /* position: relative;
  left:auto; */
  /* margin-top: 64px; */
}


.intro-image img{
  width:100%;
  display: block;
  aspect-ratio: 16 / 9;
  object-position: center;
  object-fit: cover;
  height:100%;
  width: 100%;

}

.intro{
  /* flex:0.5; */
  padding-left:2.5em;
  padding-right:2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* left: 100%; */
}

.home{
  padding-bottom: 2.5em;
  overflow: hidden;
}

.icon-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  cursor: pointer;
  color: var(--complementary-colour-blue);
  padding-top:0.5em;
}

.icon-text{
  display: none;
  color:var(--complementary-colour-blue);
  font-size: 3vh;
}

.logo{
  width: 50%;
}


.intro p{
  font-size:3vh;
}

.intro h1{
  font-size: 5vh;
}

.view-work{
  width:35vh;
  height:10vh;
  border-radius: 20px;
  background-color: var(--complementary-colour-blue);
  font-size: 3vh;
  color: white;
  font-family: 'EB Garamond', serif;
  cursor: pointer;
  border: none;
  margin:5vh;
  animation: pulse 2s 4s;
}


.contact-menu{
  display: flex;
  justify-content: center;
  gap:4vw;
  animation:pulse 2s 2s;
  width:100%;
}

.contact-menu .icon{
  color:var(--complementary-colour-blue);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding:0.1em;
  font-size: 5vh;
}

.intro-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 90%; */
  animation: slideInRight 2s;
  /* margin-top:64px */
}

@media screen and (min-width: 320px){
  .intro p{
    font-size: 2vh;
  }

  .view-work{
    height: 4.5vh;
    font-size: 2vh;
  }
}


/* a.contact-menu{
  width: 7.5em;
} */

@media screen and (min-width: 768px){
  /* .intro p{
    font-size: 1.5em;
  } */

}


@media screen and (min-width: 1280px){
  .home{
    padding-bottom: 0;
    /* overflow: hidden; */
  }
  .mobile-nav{
    display: none;
  }

  .desktop-nav{
    display: flex;
  }

  .intro h1{
    font-size: 3vw;
    text-align: center;
    color:var(--complementary-colour-blue);
  }

  
  nav{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  nav ul{
    display: flex;
    flex-direction: row;
    gap:5em;
    width: auto;
  }

  nav li{
    width: auto;
  }
  
  .container{
    flex-direction: row;
    height: 100vh;
  }

  .intro-container{
    width: 50%;
    position: relative;
    left: 100%;
    
  }

  .bars-icon{
    display: none;
  }

  .logo{
    width: auto;
  }

  .intro{
    flex:0.5;
    /* animation:fadeInUp 2s; */
  }

  .intro-image{
    flex:1;
    animation: slideInDown 2s;
    position: absolute;
    left: 0;
    height: 100%;
    width: 50%;
  }
  .intro-image img{
    width: 100%;
    min-height:100vh;
  }


  .intro p{
    font-size: 1.25vw;
    text-align: center;
  }

  #intro-text{
    text-align: left;
  }
  
  .intro h1{
    font-size: 3vw;
  }

  .intro-container{
    animation: slideInUp 2s;
    left: 50%;
    
  }

  
  .contact-menu .icon{
    color:var(--complementary-colour-blue);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding:0.1em;
    font-size: 2vw;
  }

  .icon{
    scale:1.5;
  }

  .icon-text{
    display: none;
    color:var(--complementary-colour-blue);
    font-size: 1.5vw;
  }
  
  .icon:hover{
    color: gray;
    transition: 0.5s ease-in;
  }

  /* .icon-container{
    flex-direction: row;
    transform:translate(-7em);
    transition:transform 0.5s ease-in-out;
    background-color: var(--dominant-colour);
    cursor: pointer;
    width:10em;
    padding:0;
    padding-left: 0;
  } */
  
  .view-work{
    height: 4vw;
    transition: scale 0.3s ease-in-out;
    font-size: 1.5vw;
    animation: pulse 2s 4s;
  }

  .view-work:hover{
    scale:1.05;
  }

}
