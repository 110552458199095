@import "animate.css";

.modal-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    z-index: 5;
    overflow-y: scroll;
}


.modal-container{
    position: absolute;
    top:5%;
    animation:fadeInDown 0.5s;
    width: 95%;
    background-color:var(--dominant-colour);
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);    
    z-index: 6;
    /* border: 20px solid var(--complementary-colour-blue); */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-overlay::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .modal-overlay {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.modal-container .title{
    font-size: 1.5em;
    margin: 0;
    color:var(--dominant-colour);
}


.close-button{
    display: flex;
    align-items: center;
    position: relative;
    top:0;
    color:var(--dominant-colour);
    padding-right: 0.5em;
    font-size: 1.5em;
    margin: 0;
}

.description{
    position: relative;
    bottom: 5px;
    padding:0 10px 0 10px;
    font-size: 1.25em;
}

.header{
    text-align: center;
    display: flex;
    justify-content: space-between;
    background-color: var(--complementary-colour-blue);
    padding:0 0 0 10px;
}


.footer{
    display: flex;
    justify-content: space-between;
    border-top: 2px solid lightgray;
    align-items: center;
    position: relative;
    top:50%;
}

.project-img-container{
    display: inline-block;
    text-align: center;
    overflow: hidden;
    width: 100%;
    height:15em;
}

.project-img{
    display: block;
    aspect-ratio: 16 / 9;
    object-position: center;
    object-fit: fill;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height:100%;
}


.modal-container .tool{
    background-color: var(--dominant-colour);
    font-size: 1em;
}

.modal-container .tools{
    position: relative;
    bottom: 5px;
    background-color: var(--complementary-colour-blue);
}

.left-arrow{
    position: absolute;
    top:50%;
    left:-3%;
    z-index: 7;
    color:var(--complementary-colour);
    font-size: 10vw;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.right-arrow{
    position: absolute;
    top:50%;
    right:-3%;
    z-index: 7;
    color:var(--complementary-colour);
    font-size: 10vw;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}



@media screen and (min-width: 768px){
    .modal-container{
        width: 50%;
    }

    .left-arrow{
        position: absolute;
        top:50%;
        left:15%;
        z-index: 6;
        color:var(--dominant-colour);
        font-size: 5em;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
    }

    .right-arrow{
        position: absolute;
        top:50%;
        right:15%;
        z-index: 6;
        color:var(--dominant-colour);
        font-size: 5em;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
    }

    
}


@media screen and (min-width: 1280px){
    .left-arrow{
        position: absolute;
        top:50%;
        left:22%;
        z-index: 6;
        color:var(--dominant-colour);
        font-size: 5em;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
    }

    .left-arrow:hover{
        color:var(--complementary-colour-grey);
    }

    .right-arrow:hover{
        color:var(--complementary-colour-grey);
    }

    .right-arrow{
        position: absolute;
        top:50%;
        right:22%;
        z-index: 6;
        color:var(--dominant-colour);
        font-size: 5em;
        cursor: pointer;
        transition: color 0.3s ease-in-out;
    }
    
    .close-button:hover{
        cursor: pointer;
    }

    .modal-container .title{
        font-size: 2.5em;
        margin: 0;
        color:var(--dominant-colour);
    }
    
    
    .close-button{
        display: flex;
        align-items: center;
        position: relative;
        top:0;
        color:var(--dominant-colour);
        padding-right: 0.5em;
        font-size: 2em;
        margin: 0;
        transition: 0.5 ease-in-out;
    }

    .close-button:hover{
        color: var(--complementary-colour-grey);

    }

    .modal-overlay{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.5);
        position:fixed;
        top:0;
        bottom:0;
        right:0;
        left:0;
        z-index: 5;
        overflow-y: scroll;
    }

    .modal-overlay::-webkit-scrollbar{
        display: none;
    }
    
    .modal-container{
        position: absolute;
        top:5%;
        animation:fadeInDown 0.5s;
        width: 40%;
        background-color:var(--dominant-colour);
        box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);    
        z-index: 6;
        overflow-y: auto;
    }
    
    .modal-container .title{
        font-size: 2em;
        margin: 0;
        color:var(--dominant-colour);
    }
    

    .header{
        text-align: center;
        display: flex;
        justify-content: space-between;
        background-color: var(--complementary-colour-blue);
        padding:0 0 0 10px;
    }
    
    
    .footer{
        display: flex;
        justify-content: space-between;
        border-top: 2px solid lightgray;
        align-items: center;
        position: relative;
        top:50%;
    }
    
    .project-img-container{
        display: inline-block;
        text-align: center;
        overflow: hidden;
        width: 100%;
        height:20em;
    }
    
    .project-img.website{
        display: block;
        aspect-ratio: 16 / 9;
        object-position: center;
        object-fit: fill;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        height:100%;
    }

    /* .project-img.desktop-app{
        object-fit:fill;
    } */

    img.ces-device-simulation{
        object-fit: contain;
    }

 
    
    .modal-container .tool{
        background-color: var(--dominant-colour);
        font-size: 1.25em;

    }
    
    .modal-container .tools{
        /* border-bottom: 2px solid lightgray; */
        background-color: var(--complementary-colour-blue);
        position: relative;
        bottom:5px;
    }

    .description{
        position: relative;
        bottom: 5px;
        padding:0 40px 0 40px;
    }
}

