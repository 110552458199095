.projects.container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap:4em;
    padding: 9em 0 9em 0;
    height: 100vh;
    overflow: auto;
    /* overflow-y: hidden; */
}

.page-title{
    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: var(--complementary-colour-black);
    color:var(--dominant-colour);
    padding: 10px;
    margin-bottom:80px;
    margin-top: 64px;
    position: fixed;
    z-index: 2;
}

.page-title h1{
    display: inline;
    color:var(--dominant-colour);
    margin:0;
    font-size: 2em;
}

.page-title h2{
    display: inline;
    margin: 0;
}



@media screen and (min-width:1280px) {
    .projects.container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    
}

