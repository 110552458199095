@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@800&display=swap');
@import "animate.css";


.contact-me.container{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100vw;
  height: 100vh;
  animation: slideInDown 1.5s;
  padding-bottom: 0.5em;
}

.contact-me.container .page-title{
  padding: 0;
  margin-top: 2.5em;
}

.contact-me.container form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: 70%;
  padding: 2.5%;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  margin-top: 6em;
}

.form-input{
  display: flex;
  flex-direction: column;
  top: 2.5%;
  width: 100%;
  height: 60%;
  font-family: var(--main-font);
}

.form-input textarea{
  background-color: lightgray;
  width: 100%;
  height: 35vh;
  border-radius: 5px;
  outline: none;
  font-size:1em;
  color:var(--complementary-colour-grey);
  resize: none;
  border:none;
  font-family: var(--main-font);
  margin: 1.25% 0 1.25%;
  padding:0 10px 0 10px;
}

.form-input input[type="text"]{
  background-color: lightgrey;
  border-radius: 5px;
  color: var(--complementary-colour-grey);
  font-size: 1em;
  font-family: var(--main-font);
  margin: 1.25% 0 1.25%;
  border: none;
  width: 100%;
  height: 10%;
  padding:0 10px 0 10px;;
}

.form-input input[type="email"]{
  background-color: lightgrey;
  border-radius: 5px;
  color: var(--complementary-colour-grey);
  font-size: 1em;
  font-family: var(--main-font);
  margin: 1.25% 0 1.25%;
  border: none;
  width: 100%;
  height: 10%;
  padding:0 10px 0 10px;;
}

.form-input ::placeholder{
  color: var(--complementary-colour-grey);
  font-family: var(--main-font);
}

.form-input input[type="submit"]{
    width:25vh;
    height:10vh;
    border-radius: 20px;
    background-color: var(--complementary-colour-blue);
    font-size: 2.5vh;
    color: white;
    font-family: 'EB Garamond', serif;
    cursor: pointer;
    border: none;
    margin:2.5% 0 2.5% 0;
    animation: pulse 2s 4s;
}

.contact-details{
  top: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 40%;
  width: 100%;
  font-size: 1em;
  margin: 0;
  /* margin-top: -5em; */
}

.contact-details a{
  color:var(--complementary-colour-black);
  transition:color 0.3s ease-in-out;
}

.contact-details a:visited{
  color:var(--complementary-colour-black)
}

.contact-details a:hover{
  color:var(--complementary-colour-purple)
}


.contact-container{
  display: flex;
  width: 92.5%;
  /* margin:0 2.5%; */
  /* padding:2.5%; */
}

.contact-text{
  word-break: break-all;
  margin-left: 2.5%;
  font-family: var(--main-font);
}

.contact-details .icon{
  color: var(--complementary-colour-blue);
}

@media screen and (min-width:1280px) {
  
  .contact-me.container{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw;
    height: 100vh;
  }

  .contact-me.container .page-title{
    padding: 0;
    margin-top: 2.5em;
  }

  .contact-me.container form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding: 2.5%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
    margin-top: 7.5em;
  }

  .form-input{
    display: flex;
    flex-direction: column;
    top: 2.5%;
    width: 50%;
    height: 92.5%;
    font-family: var(--main-font);
  }

  .form-input textarea{
    background-color: lightgray;
    width: 100%;
    /* height: 35%; */
    border-radius: 5px;
    outline: none;
    font-size:1.5vw;
    color:var(--complementary-colour-grey);
    resize: none;
    border:none;
    font-family: var(--main-font);
    margin: 1.25% 0 1.25%;
    padding:0 10px 0 10px;
  }

  .form-input input[type="text"]{
    background-color: lightgrey;
    border-radius: 5px;
    color: var(--complementary-colour-grey);
    font-size: 1.5vw;
    font-family: var(--main-font);
    margin: 1.25% 0 1.25%;
    border: none;
    width: 100%;
    height: 10%;
    padding:0 10px 0 10px;
  }

  .form-input input[type="email"]{
    background-color: lightgrey;
    border-radius: 5px;
    color: var(--complementary-colour-grey);
    font-size: 1.5vw;
    font-family: var(--main-font);
    margin: 1.25% 0 1.25%;
    border: none;
    width: 100%;
    height: 10%;
    padding:0 10px 0 10px;
  }

  .form-input ::placeholder{
    color: var(--complementary-colour-grey);
    font-family: var(--main-font);
  }

  .form-input input[type="submit"]{
    height: 4vw;
    transition: scale 0.3s ease-in-out;
    font-size: 1.5vw;
    animation: pulse 2s 4s;
    border-radius: 20px;
    background-color: var(--complementary-colour-blue);
    font-size: 3vh;
    color: white;
    font-family: 'EB Garamond', serif;
    cursor: pointer;
    border: none;
    margin:2.5% 0 2.5% 0;
}

  .form-input input[type="submit"]:hover{
    scale:1.05;
  }

  .contact-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    /* margin: 0 0 0 2.5%; */
    left: 50%;
    width: 45%;
    height: 77.5%;
    font-size: 2vw;
  }

  .contact-container{
    display: flex;
    flex-direction: row;
    word-wrap: break-word;
    padding:2.5% 0 2.5% 0;
  }

  .contact-text{
    word-break: break-all;
    font-family: var(--main-font);
  }

  .contact-details .icon{
    color: var(--complementary-colour-blue);
  }

}
